<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Zaka",
};
</script>
<style scoped>
.v-overlay {
  z-index: 6 !important;
}
a {
  text-decoration: none;
}
</style>
