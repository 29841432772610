import dataAPI from "./dataAPI";

export default {
  async getData(payload) {
    try {
      const Response = await dataAPI.getData(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async getData1(payload) {
    try {
      const Response = await dataAPI.getData1(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async getData2(payload) {
    try {
      const Response = await dataAPI.getData2(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async Applicants(payload) {
    try {
      const Response = await dataAPI.Applicants(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async appAdvice(payload) {
    try {
      const Response = await dataAPI.appAdvice(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async casezi001(payload) {
    try {
      const Response = await dataAPI.casezi001(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async zip_zipcode(payload) {
    try {
      const Response = await dataAPI.zip_zipcode(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async zip_sigungucode(payload) {
    try {
      const Response = await dataAPI.zip_sigungucode(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async scode(payload) {
    try {
      const Response = await dataAPI.scode(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
};
