import api from "./service";
const code = {
  namespaced: true,
  state: {
    ZIP_SIDOCODE: [],
    ZIP_SIGUNGUCODE: [],
    CPID_CODE: [],
  },
  getters: {
    GE_ZIP_SIDOCODE: (state) => state.ZIP_SIDOCODE,
    GE_ZIP_SIGUNGUCODE: (state) => state.ZIP_SIGUNGUCODE,
    GE_CPID_CODE: (state) => state.CPID_CODE,
  },
  mutations: {
    MU_ZIP_SIDOCODE: (state, payload) => {
      state.ZIP_SIDOCODE = payload.data;
    },
    MU_ZIP_SIGUNGUCODE: (state, payload) => {
      state.ZIP_SIGUNGUCODE = payload.data;
    },
    MU_CPID_CODE: (state, payload) => {
      state.CPID_CODE = payload.data;
    },
  },
  actions: {
    async ZIP_SIDOCODE({ commit }, payload) {
      const response = await api.zip_zipcode(payload);
      commit("MU_ZIP_SIDOCODE", response);
      return response;
    },
    async ZIP_SIGUNGUCODE({ commit }, payload) {
      const response = await api.zip_zipcode(payload);
      commit("MU_ZIP_SIGUNGUCODE", response);
      return response;
    },
    async CPID_CODE({ commit }, payload) {
      const response = await api.scode(payload);
      commit("MU_CPID_CODE", response);
      return response;
    },
  },
};

export default code;
