// src/service/index.js
import loginAPI from "./loginAPI";

export default {
  async login(para) {
    try {
      const loginResponse = await loginAPI.login(para);
      return loginResponse;
    } catch (err) {
      console.error(err);
    }
  },
  async Register(para) {
    try {
      const Response = await loginAPI.Register(para);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
};
