import axios from "axios";
axios.defaults.preflightContinue = true;
axios.defaults.crossDomain = true;
//axios.defaults.withCredentials = !!token;
axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  //"Access-Control-Allow-Origin": "//withtomatoseven.kr",
  //"Content-Type": "application/json;charset=UTF-8",
  //"Access-Control-Allow-Origin": "*",
  //"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  //Accept: "application/json",
  //Authorization: "zaka",
};

const getUserInfo = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/getApplicants.php",
    JSON.stringify(payload)
  );
};
const getUserInfo1 = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/getApplicants1.php",
    JSON.stringify(payload)
  );
};
const Applicants = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/getApplicants.php",
    JSON.stringify(payload)
  );
};
const getUserInfo2 = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/getApplicants1.php",
    JSON.stringify(payload)
  );
};
const setAdvice = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/getAppAdvice.php",
    JSON.stringify(payload)
  );
};
const casezi001 = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/casezi.php",
    JSON.stringify(payload)
  );
};
const zip_zipcode = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/zipcode.php",
    JSON.stringify(payload)
  );
};
const scode = (payload) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/scode.php",
    JSON.stringify(payload)
  );
};
// const getUserInfo3 = (para) => {
//   return axios.get("http://tomatoseven.cafe24.com/mecs/getApplicants.php", {
//     params: { para },
//   });
// };
// const setAdvice = (para) => {
//   return axios.get("http://tomatoseven.cafe24.com/mecs/getAppAdvice.php", {
//     params: { para },
//   });
// };

export default {
  async getData(payload) {
    console.log(payload);
    try {
      const getUserInfoPromise = await getUserInfo(payload);
      return getUserInfoPromise;
    } catch (err) {
      console.error(err);
    }
  },
  async getData1(payload) {
    console.log(payload);
    try {
      const Response = await getUserInfo1(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async getData2(payload) {
    console.log(payload);
    try {
      const Response = await getUserInfo2(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async Applicants(payload) {
    console.log(payload);
    try {
      const getUserInfoPromise = await Applicants(payload);
      return getUserInfoPromise;
    } catch (err) {
      console.error(err);
    }
  },

  async appAdvice(payload) {
    console.log(payload);
    try {
      const AdvicePromise = await setAdvice(payload);
      return AdvicePromise;
    } catch (err) {
      console.error(err);
    }
  },
  async casezi001(payload) {
    try {
      const Response = await casezi001(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async zip_zipcode(payload) {
    try {
      const Response = await zip_zipcode(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async zip_sigungucode(payload) {
    try {
      const Response = await zip_zipcode(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
  async scode(payload) {
    try {
      const Response = await scode(payload);
      return Response;
    } catch (err) {
      console.error(err);
    }
  },
};
