import api from "./service";

const userStore = {
  namespaced: true,
  state: {
    userName: "도로시",
    respCode: [
      { state: "승인", id: "00" },
      { state: "미승인", id: "70" },
    ],
    cas_id: "52000025369",
    service1: [{ state: "IPM해충방제 3개월 무료 서비스", id: "1" }],
    service1_1: [{ state: "해충방제 1개월 무료 서비스", id: "1" }],
    service2: [
      {
        state: "내·외부 유리창 세척(2층 이상 매장은 내부 유리 세척만 가능)",
        id: "1",
      },
      { state: "에어컨 필터 세척(최대 3대까지)", id: "2" },
      { state: "에어컨 실외기 세척(최대  3대까지)", id: "3" },
      { state: "냉장고 성애&먼지 제거 서비스(최대 1대)", id: "4" },
    ],
    service3: [
      { state: "52000025369", id: "52000025369" },
      { state: "52000025370", id: "52000025370" },
    ],
    counte: null,
  },
  getters: {
    GE_USER_NAME: (state) => state.userName,
    GE_RESPCODE: function (state) {
      return state.respCode;
    },
    GE_SERVICE3: function (state) {
      return state.service3;
    },

    GE_SERVICE2: function (state) {
      return state.service2;
    },
    GE_SERVICE1: function (state) {
      if (state.cas_id == "52000025370") {
        return state.service1_1;
      } else {
        return state.service1;
      }
    },
  },
  mutations: {
    MU_USER_NAME: (state, payload) => {
      state.userName = payload.userName;
    },
    MU_CPID_CODE: (state, payload) => {
      state.cas_id = payload.cas_id;
    },
  },
  actions: {
    async GET_DATA({ commit }, para) {
      const response = await api.getData(para);
      commit("MU_CPID_CODE", para);
      return response; // 로그인 결과를 리턴한다
    },
    async GET_DATA1(store, para) {
      const response = await api.getData1(para);
      return response; // 로그인 결과를 리턴한다
    },
    async GET_DATA2(store, para) {
      const response = await api.getData2(para);
      return response; // 로그인 결과를 리턴한다
    },
    async insertApplicants(store, para) {
      const response = await api.Applicants(para);
      return response; // 로그인 결과를 리턴한다
    },

    async insertAdvice(store, para) {
      const response = await api.appAdvice(para);
      return response; // 로그인 결과를 리턴한다
    },
    async selectAdvice(store, para) {
      const response = await api.appAdvice(para);
      return response; // 로그인 결과를 리턴한다
    },
    async updateAdvice(store, para) {
      const response = await api.appAdvice(para);
      return response; // 로그인 결과를 리턴한다
    },
    async deleteAdvice(store, para) {
      const response = await api.appAdvice(para);
      return response; // 로그인 결과를 리턴한다
    },
    async OkAuth(store, para) {
      const response = await api.Applicants(para);
      return response; // 로그인 결과를 리턴한다
    },
    async getService1(store, para) {
      const response = await api.Applicants(para);
      return response; // 로그인 결과를 리턴한다
    },
  },
};

export default userStore;
