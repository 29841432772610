import api from "./service";
const casezi = {
  namespaced: true,
  state: {
    CPID: "52000025369",
    SUB_NO: "",
    CPID_KIT: "52000025370",
    respCode: [
      { state: "승인", id: "00" },
      { state: "미승인", id: "70" },
    ],
    counte: null,
  },
  getters: {
    GE_CPID: (state) => state.CPID,
    GE_SUB_NO: (state) => state.SUB_NO,
    GE_CPID_KIT: (state) => state.CPID_KIT,
  },
  mutations: {
    MU_SUB_NO: (state, payload) => {
      state.SUB_NO = payload.sub_no;
    },
  },
  actions: {
    async REQU_CASEZI({ commit }, payload) {
      const response = await api.casezi001(payload);
      commit("MU_SUB_NO", payload);
      return response;
    },
  },
};

export default casezi;
