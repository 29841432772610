import Vue from "vue";
import Vuex from "vuex";

// 작성한 모듈을 가져옵니다.
import userStore from "@/store/modules/userStore.js";
import register from "@/store/modules/register.js";
import eventMsg from "@/store/modules/eventMsg.js";
import casezi from "@/store/modules/casezi.js";
import code from "@/store/modules/code.js";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    userStore,
    register,
    eventMsg,
    casezi,
    code,
  },

  strict: debug,
});
