<template>
  <v-app>
    <v-main>
      <v-container class="fill-height">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  naem: "lguplus",
};
</script>

<style></style>
