const eventMsg = {
  namespaced: true,
  state: {
    dialog: false,
    dialogHead: "",
    dialogMessage: "",
  },
  getters: {
    GE_USER_NAME: (state) => state.userName,
    GE_RESPCODE: function (state) {
      return state.respCode;
    },
    GE_SERVICE2: function (state) {
      return state.service2;
    },
  },
  mutations: {
    MU_DIALOG_MSG: (state, payload) => {
      state.dialog = true;
      state.dialogHead = payload.Head;
      state.dialogMessage = payload.Message;
    },
  },
  actions: {
    AC_DIALOG_MSG({ commit }, payload) {
      commit("MU_DIALOG_MSG", payload);
    },
  },
};

export default eventMsg;
