import router from "../../router";
import apii from "./dregister";
import * as types from "./mutation_types";
import { UID, IS_AUTH, ERROR_STATE } from "./mutation_types";
import CryptoJS from "crypto-js";

const setUID = ({ commit }, data) => {
  console.log("actions setUID  commit", data);
  commit(UID, data);
};

const setErrorState = ({ commit }, data) => {
  commit(ERROR_STATE, data);
};

const setIsAuth = ({ commit }, data) => {
  console.log("actions setIsAuth  commit", data);
  commit(IS_AUTH, data);
};
// 백엔드에서 반환한 결과값을 가지고 로그인 성공 실패 여부를 vuex에 넣어준다.
const processResponse = (store, loginResponse) => {
  console.log("actions processResponse", loginResponse);
  switch (loginResponse) {
    case "noAuth":
      setErrorState(store, "Wrong ID or Password");
      setIsAuth(store, false);
      break;
    case "done":
      setErrorState(store, "No period");
      setIsAuth(store, false);
      break;
    default:
      console.log(
        "actions processResponse  default",
        loginResponse.data.use_id
      );

      if (loginResponse.data.repCode === "00") {
        setUID(store, loginResponse.data.use_id);
        setErrorState(store, "");
        setIsAuth(store, true);
      } else {
        setUID(store, "");
        setErrorState(store, "");
        setIsAuth(store, false);
      }
  }
};

const userStore = {
  namespaced: true,
  state: {
    userName: "도로시",
    isAuth: false,
    uid: "",
    errorState: "",
    menu01: [
      {
        id: 1,
        name: "이벤트 신청 접수",
        icon: "mdi-playlist-edit",
        to: "Applicants",
      },
      {
        id: 2,
        name: "이벤트 승인 이력 조회",
        icon: "mdi-list-status",
        to: "WorkLog",
      },
      {
        id: 4,
        name: "지역별 등록표",
        icon: "mdi-playlist-edit",
        to: "ApplicantChart",
      },
      {
        id: 3,
        name: "관리자",
        icon: "mdi-account-supervisor",
        children: [
          {
            id: 31,
            name: "사용자 관리",
            icon: "mdi-account-circle-outline",
            to: "UserList",
          },
        ],
      },
    ],
  },
  getters: {
    getUid: (state) => state.uid,
    getErrorState: (state) => state.errorState,
    getIsAuth: (state) => {
      console.log("getters  getIsAuth", state.isAuth);
      return state.isAuth;
    },
    GE_MENU01: function (state) {
      if (state.isAuth === true) {
        return state.menu01;
      } else {
        return [];
      }
    },
  },
  mutations: {
    [types.UID](state, uid) {
      console.log("mutations  UID", uid);
      state.uid = uid;
    },
    [types.ERROR_STATE](state, errorState) {
      state.errorState = errorState;
    },
    [types.IS_AUTH](state, isAuth) {
      state.isAuth = isAuth;
      console.log("mutation types.IS_AUTH", state.isAuth);
    },
    logout(state) {
      state.uid = null;
      state.isAuth = false;
    },
  },
  actions: {
    async login({ dispatch }, para) {
      console.log("actions login", para.uid + para.password);
      let Response = await apii.login(para);
      if (Response.data.use_init_yn === "0") {
        const token = Response.data.session_id;
        localStorage.setItem("access.token", token);
        console.log(" ", token);
        dispatch("sessionLogin");
      }
      return Response; // 로그인 결과를 리턴한다
    },
    async sessionLogin(store) {
      const token = localStorage.getItem("access.token");
      const config = {
        fun_code: "session",
        session_id: token,
      };
      console.log("sessionLogin", token);
      const Response = await apii.login(config);
      console.log("actions session", Response);
      processResponse(store, Response);

      console.log("로그인 결과를 리턴한다", store.getters.getIsAuth);
      return store.getters.getIsAuth; // 로그인 결과를 리턴한다
    },
    async Register(store, para) {
      const Response = await apii.Register(para);
      return Response; // 로그인 결과를 리턴한다
    },
    async userList(store, para) {
      const Response = await apii.Register(para);
      return Response; // 로그인 결과를 리턴한다
    },
    async userInityn(store, para) {
      const Response = await apii.Register(para);
      return Response; // 로그인 결과를 리턴한다
    },
    async LogOut(store) {
      const token = localStorage.getItem("access.token");
      const config = {
        fun_code: "sessionLogout",
        session_id: token,
      };
      console.log("session logout", token);
      const Response = await apii.login(config);
      console.log("actions session", Response);
      setUID(store, "");
      setErrorState(store, "");
      setIsAuth(store, false);
      localStorage.setItem("access.token", " ");
      router.push({ name: "Login" });

      // return Response // 로그인 결과를 리턴한다
    },
    createHashedPassword(store, value) {
      let key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
      let iv = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
      let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
      console.log("encrypted 결과를 리턴한다", encrypted);
      return encrypted; // 로그인 결과를 리턴한다
    },
  },
};

export default userStore;
