import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import defaultLayout from "@/layout/default/index.vue";
import pageLayout from "@/layout/page/index.vue";
import lguplus from "@/layout/lguplus/index.vue";

Vue.use(VueRouter);

const certified = (to, from, next) => {
  if (store.getters["register/getIsAuth"] === true) {
    alert("이미 로그인 되었습니다.");
    next("/");
  } else {
    next();
  }
};

// const job = (x) => {
//   return new Promise((resolve, reject) => {
//   //  console.log(x)
//     resolve(x)
//   })
// }

const notCertified = (to, from, next) => {
  if (store.dispatch["register/sessionLogin"] === false) {
    next("/Login");
  } else {
    next();
  }

  // async function findUser () {
  //   try {
  //     let user = await store.dispatch['register/sessionLogin']
  //     user = await store.getters['register/getIsAuth']
  //     if (user === false) {
  //       alert('인증되지 않았습니다..')
  //       next('/Login')
  //     } else {
  //       next()
  //     }
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // console.log('notCertified', store.dispatch['register/sessionLogin'])
  // console.log('notCertified', store.getters['register/getIsAuth'])
  // if (store.dispatch['register/sessionLogin'] === true) {
  //   if (store.getters['register/getIsAuth'] === false) {
  //     alert('인증되지 않았습니다..')
  //     next('/Login')
  //   } else {
  //     next()
  //   }
  // }
};
// const notCertified = async function () {
//   //    const [a, b] = await Promise.all([job(3), job(9)])
//   let user = await job(await store.dispatch['register/sessionLogin'])
//   console.log('user ' + user)
//   user = await notCertified1()
// }

const Login = () =>
  import(/* webpackChunkName: "Login" */ "../views/Login.vue");
const registerForm = () =>
  import(
    /* webpackChunkName: "registerForm" */ "@/components/registerForm.vue"
  );
const UserList = () =>
  import(/* webpackChunkName: "UserList" */ "../views/UserList.vue");
const TestPage = () =>
  import(/* webpackChunkName: "TestPage" */ "../views/TestPage.vue");
const Applicants = () =>
  import(/* webpackChunkName: "Applicants" */ "../views/Applicants.vue");
const Breakpoints = () =>
  import(/* webpackChunkName: "Breakpoints" */ "../views/Breakpoints.vue");

const routes = [
  {
    path: "/",
    name: "defaultLayout",
    beforeEnter: notCertified,
    component: defaultLayout,
    children: [
      {
        path: "home",
        name: "Home",
        beforeEnter: notCertified,
        component: Home,
      },
      {
        path: "Login",
        name: "Login",
        beforeEnter: certified,
        component: Login,
      },
      {
        path: "Register",
        name: "Register",
        component: registerForm,
      },
      {
        path: "UserList",
        name: "UserList",
        beforeEnter: notCertified,
        component: UserList,
      },
      {
        path: "TestPage",
        name: "TestPage",
        component: TestPage,
      },
      {
        path: "Applicants",
        name: "Applicants",
        beforeEnter: notCertified,
        component: Applicants,
      },
      {
        path: "ApplicantChart",
        name: "ApplicantChart",
        beforeEnter: notCertified,
        component: () =>
          import(
            /* webpackChunkName: "ApplicantChat" */ "../views/ApplicantChart.vue"
          ),
      },
      {
        path: "ApplicantChart2",
        name: "ApplicantChart2",
        beforeEnter: notCertified,
        component: () =>
          import(
            /* webpackChunkName: "ApplicantChat2" */ "../views/ApplicantChart2.vue"
          ),
      },
      {
        path: "WorkLog",
        name: "WorkLog",
        beforeEnter: notCertified,
        component: () => import("../views/WorkLog"),
      },
      {
        path: "about",
        name: "About",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "breakpoints",
        name: "Breakpoints",
        component: Breakpoints,
      },
    ],
  },
  {
    path: "/pageLayout",
    name: "pageLayout",
    beforeEnter: notCertified,
    component: pageLayout,
    children: [
      {
        path: "pagea",
        name: "pagea",
        component: () =>
          import(/* webpackChunkName: "page/a" */ "../views/page/a.vue"),
      },
    ],
  },
  {
    path: "/lguplus",
    name: "lguplus",
    component: lguplus,
    children: [
      {
        path: "successplaza",
        redirect: { name: "successpack" },
        name: "successplaza",
        component: () =>
          import(
            /* webpackChunkName: "lguplus/successplaza" */ "../views/lguplus/successplaze.vue"
          ),
      },
      {
        path: "successpack",
        name: "successpack",
        component: () =>
          import(
            /* webpackChunkName: "lguplus/successpack" */ "../views/lguplus/successpack.vue"
          ),
      },
      {
        path: "daumpost",
        name: "daumpost",
        component: () =>
          import(
            /* webpackChunkName: "tevent/daumpost" */ "../views/lguplus/daumpost.vue"
          ),
      },
      {
        path: "welcomekit",
        name: "welcomekit",
        component: () =>
          import(
            /* webpackChunkName: "lguplus/welcomekit" */ "../views/lguplus/welcomekit.vue"
          ),
      },
    ],
  },
  {
    path: "/tomato7",
    name: "tomato7",
    component: lguplus,
    children: [
      {
        path: "tomato7Home",
        name: "tomato7Home",
        beforeEnter() {
          location.href = "//www.tomato7.co.kr";
        },
      },
      {
        path: "tomato758",
        name: "tomato758",
        beforeEnter() {
          location.href = "//www.tomato7.co.kr/58";
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
