import axios from "axios";

const getUserInfo = (para) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/usePers.php",
    JSON.stringify(para)
  );
};

const setRegister = (para) => {
  return axios.post(
    "//withtomatoseven.kr/mecs/usePers.php",
    JSON.stringify(para)
  );
};
// const getUserInfo = (para) => {
//   return axios.get("http://tomatoseven.cafe24.com/mecs/usePers.php", {
//     params: { para },
//   });
// };

// const setRegister = (para) => {
//   return axios.get("http://tomatoseven.cafe24.com/mecs/usePers.php", {
//     params: { para },
//   });
// };
export default {
  async login(para) {
    console.log("default login ", para);
    try {
      const getUserInfoPromise = await getUserInfo(para);
      console.log("loginapi getUserInfoPromise ", getUserInfoPromise);
      return getUserInfoPromise;
    } catch (err) {
      console.error(err);
    }
  },
  async Register(para) {
    try {
      const getUserInfoPromise = await setRegister(para);
      return getUserInfoPromise;
    } catch (err) {
      console.error(err);
    }
  },
};
