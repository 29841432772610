<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-toolbar> Page layout </v-toolbar>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  naem: "PageLayout",
};
</script>

<style></style>
