<template>
  <v-container fluid> </v-container>
</template>

<script>
export default {
  name: "TestPage",
  data() {
    return {
      inset: true,
    };
  },
};
</script>
