<template>
  <v-app id="zaka">
    <v-navigation-drawer v-model="toggle" app left>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title />
          <v-list-item-subtitle />
        </v-list-item-content>
      </v-list-item>
      <div>
        <v-treeview
          v-model="tree"
          :open="initiallyOpen"
          :items="items"
          :search="search"
          :filter="filter"
          activatable
          rounded
          color="success"
          dense
          item-text
          item-key="id"
          item-disabled="locked"
          open-on-click
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.icon">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
            <v-icon v-else>
              {{ item.icon }}
            </v-icon>
            <router-link :to="{ name: item.to }" class="ml-2">
              {{ item.name }}
            </router-link>
          </template>
        </v-treeview>
      </div>
    </v-navigation-drawer>
    <v-container fluid class="grey lighten-5">
      <v-app-bar app>
        <v-app-bar-nav-icon v-if="userStatus" @click="toggle = !toggle" />
        <v-img
          lazy-src="@/assets/logo.png"
          max-height="30"
          max-width="30"
          src="@/assets/logo.png"
        />
        <v-toolbar-title class="ml-1">토마토세븐</v-toolbar-title>
        <v-spacer />
        {{ userName }}
        <!-- <router-link to="/Login">
        <v-btn icon>
          <v-icon v-if="userStatus === true" >mdi-account-plus</v-icon>
          <v-icon v-else >mdi-account-remove</v-icon>
        </v-btn>
        </router-link> -->
        <div class="text-center">
          <v-menu v-if="userStatus === true" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="LogOut">
                <v-list-item-title>로그아웃</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link v-else to="/Login">
            <v-icon>mdi-account-remove</v-icon>
          </router-link>
        </div>
      </v-app-bar>
      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid class="grey lighten-4">
          <!-- If using vue-router -->
          <router-view />
        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex"; // mapGetters를 추가한다
const register = "register";

export default {
  name: "Zaka",
  data() {
    return {
      userName: "",
      toggle: false,
      search: null,
      caseSensitive: false,
      initiallyOpen: ["public"],
      tree: [],
    };
  },
  computed: {
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    // 1) 이름 지정해서 가져오기
    ...mapGetters(register, {
      storeUserName: "getUid",
      storeUserStatus: "getIsAuth",
      menu01: "GE_MENU01",
    }),
    items() {
      var selectAll = [
        {
          id: 33,
          name: "home",
          icon: "mdi-view-dashboard-outline",
          to: "Home",
        },
      ];
      return selectAll.concat(this.menu01);
    },
  },
  watch: {
    // getters에 watch를 걸 수 있습니다.
    storeUserName(val) {
      this.userName = val;
    },
    storeUserStatus(val) {
      this.userStatus = val;
    },
  },
  created() {
    this.userName = this.storeUserName;
    this.userStatus = this.storeUserStatus;
  },
  methods: {
    ...mapGetters(register, ["getUid"]),
    ...mapActions(register, ["LogOut"]),
  },
};
</script>
<style scoped>
.v-overlay {
  z-index: 6 !important;
}
a {
  text-decoration: none;
}
</style>
